import type { NotificationType } from "smavesto.core/lib/business-logic/notifications/types/NotificationType";
import CancellationNotificationModal from "./CancellationNotificationModal";
import ConfirmDocumentsNotificationModal from "./ConfirmDocumentsNotificationModal";
import ProfileVerificationModal from "./ProfileVerificationModal";
import SurveyNotificationModal from "./SurveyNotificationModal";
import DataPrivacySupplierModal from "./DataPrivacySupplierModal";
import AdventCalendarModal from "./AdventCalendarModal";

export interface NotificationModalSwitchProps {
  visible: boolean;
  userUri: string;
  onClose: () => void;
  type?: NotificationType;
}

const NotificationModalSwitch: React.FunctionComponent<
  NotificationModalSwitchProps
> = ({ visible, userUri, onClose, type }) => {
  switch (type) {
    case "CANCELLATION_REQUEST":
      return (
        <CancellationNotificationModal
          userUri={userUri}
          visible={visible}
          onClose={onClose}
        />
      );
    case "CONFIRM_DOCUMENTS":
      return (
        <ConfirmDocumentsNotificationModal
          userUri={userUri}
          visible={visible}
          onClose={onClose}
        />
      );
    case "OPEN_SURVEY":
      return (
        <SurveyNotificationModal
          userUri={userUri}
          visible={visible}
          onClose={onClose}
        />
      );
    case "PROFILE_VERIFICATION":
      return (
        <ProfileVerificationModal
          userUri={userUri}
          visible={visible}
          onClose={onClose}
        />
      );
    case "NEW_SUPPLIER_PRIVACY_SETTING":
      return (
        <DataPrivacySupplierModal
          userUri={userUri}
          visible={visible}
          onClose={onClose}
          variant="new-supplier"
        />
      );
    case "UPGRADE_SUPPLIER_PRIVACY":
      return (
        <DataPrivacySupplierModal
          userUri={userUri}
          visible={visible}
          onClose={onClose}
          variant="upgrade-supplier"
        />
      );
    case "ADVENT_CALENDAR":
      return (
        <AdventCalendarModal
          userUri={userUri}
          visible={visible}
          onClose={onClose}
        />
      );
    default:
      return null;
  }
};

export default NotificationModalSwitch;
