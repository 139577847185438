import React from "react";

import { LoadingHintText } from "@components/layout/loading-indicator/loading-hint-text";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import {
  getConfirm,
  mapDBValue
} from "@page-components/onboarding/Summary/helpers";
import { SectionWrapper } from "@page-components/onboarding/Summary/SectionWrapper";
import { ProfileKeyValue } from "@src/page-components/onboarding/Summary/ProfileKeyValue";
import { knowledgeState } from "@states/knowledge.state";
import KnowledgeProfileKeyValueItem from "@src/page-components/dashboard/Profile/knowledge/KnowledgeProfileKeyValueItem";

export const Knowledge: React.FunctionComponent = () => {
  const [, contents] = useAsyncSelector(knowledgeState("loggedInUser"));

  return (
    <SectionWrapper title="Kenntnisse">
      {contents ? (
        <>
          <ProfileKeyValue label="Bildungsstand:" alignSelectionBottom>
            {contents.education.education === "SONSTIGES"
              ? contents.education.educationCustom
              : mapDBValue(
                  "knowledge",
                  "education",
                  contents.education.education
                )}
          </ProfileKeyValue>
          <KnowledgeProfileKeyValueItem
            name="Index-Aktienfonds"
            experienceItem={contents.experiences.equities}
          />

          <KnowledgeProfileKeyValueItem
            name="Index-Rentenfonds"
            experienceItem={contents.experiences.bonds}
          />

          <KnowledgeProfileKeyValueItem
            name="Index-Rohstoffen"
            experienceItem={contents.experiences.commodities}
          />

          <KnowledgeProfileKeyValueItem
            name="Kryptowährungen (Zertifikate)"
            experienceItem={contents.experiences.crypto}
          />

          <ProfileKeyValue label="Video gesehen:" alignSelectionBottom>
            {getConfirm(contents.experiences.video)}
          </ProfileKeyValue>
        </>
      ) : (
        <LoadingHintText />
      )}
    </SectionWrapper>
  );
};
