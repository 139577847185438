import { CloseCircleOutlined } from "@ant-design/icons";
import CustomModalContent from "@src/components/layout/modal/CustomModalContent";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import {
  customerSupplierAccreditationState,
  customerSupplierState
} from "@src/state/settings.state";
import {
  SActionButtonsWrapper,
  SButton
} from "@src/styled-components/form/SButtons";
import { bundledLoadingState } from "@src/utils/recoil/bundledLoadingState";
import { Button, Modal } from "antd";
import { useRouter } from "next/router";
import type { NotificationModalSwitchProps } from "./NotificationModalSwitch";

const DataPrivacySupplierModal: React.FunctionComponent<
  NotificationModalSwitchProps & {
    variant: "new-supplier" | "upgrade-supplier";
  }
> = ({ visible, userUri, onClose, variant }) => {
  const [supplierLoadingState, supplier] = useAsyncSelector(
    customerSupplierState("loggedInUser")
  );

  const [
    customerSupplierAccreditationsLoadingState,
    customerSupplierAccreditations
  ] = useAsyncSelector(customerSupplierAccreditationState("loggedInUser"));

  const isLoading =
    bundledLoadingState([
      customerSupplierAccreditationsLoadingState,
      supplierLoadingState
    ]) === "loading";

  const fullAddress = supplier
    ? `${supplier?.street ?? ""} ${supplier?.streetNumber ?? ""}, ${
        supplier?.zip ?? ""
      } ${supplier?.city ?? ""}`
    : ``;

  const supplierName = `${supplier?.name}, ${fullAddress} `;

  const router = useRouter();

  // Find the supplier with status "NOT_SET" (new supplier)
  const newSupplier = customerSupplierAccreditations?.find(
    a => a.status === "NOT_SET"
  );

  // Find the supplier with status "LEGACY_APPROVED" (eligible for upgrade)
  const supplierToUpgrade = customerSupplierAccreditations?.find(
    a => a.status === "LEGACY_APPROVED"
  );

  // Redirects to profile page and opens the required modal
  const openEditModal = () => {
    if (variant === "new-supplier") {
      // Redirect to the profile page and open the required modal
      router.push(
        `/dashboard/profile?section=privacy&action=open-privacy-modal&supplierID=${newSupplier?.customerSupplier?.supplierID}&editAccreditationType=${newSupplier?.editAccreditationType}`
      );
    } else if (variant === "upgrade-supplier") {
      // Redirect to the profile page and open the required modal
      router.push(
        `/dashboard/profile?section=privacy&action=open-privacy-modal&supplierID=${supplierToUpgrade?.customerSupplier?.supplierID}&editAccreditationType=${supplierToUpgrade?.editAccreditationType}`
      );
    }
  };

  if (isLoading) return null;

  return (
    <Modal
      centered
      closable
      closeIcon={<CloseCircleOutlined />}
      onCancel={onClose}
      width="100%"
      style={{ maxWidth: 900 }}
      visible={visible}
      modalRender={() => (
        <CustomModalContent>
          <Button
            onClick={onClose}
            style={{
              color: "inherit",
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
              margin: "2rem",
              width: 40,
              height: 40
            }}
            type="text"
            icon={<CloseCircleOutlined />}
          />
          <h2 style={{ margin: 0, marginBottom: "1rem" }}>Hinweis</h2>

          {variant === "upgrade-supplier" && (
            <p>
              Die Datenübermittlung an Ihren Vermittler {supplierName} hat sich
              geändert, bitte lesen Sie sich die neue Version durch indem Sie
              auf jetzt bearbeiten klicken und stimmen Sie anschließend der
              Datenübermittlung zu oder lehnen diese ab.
            </p>
          )}

          {variant === "new-supplier" && (
            <p>
              Es ist ein weiterer Vermittler &quot;
              {newSupplier?.customerSupplier?.name}&quot; Ihres Vermittlers{" "}
              &quot;{supplierName}&quot; hinzugekommen, bitte lesen Sie sich die
              Einwilligung zur Datenübermittlung durch indem Sie auf jetzt
              bearbeiten klicken und stimmen Sie anschließend der
              Datenübermittlung zu oder lehnen diese ab.
            </p>
          )}

          {userUri && (
            <SActionButtonsWrapper>
              <SButton className="reset" onClick={onClose}>
                Später
              </SButton>

              <SButton type="primary" onClick={openEditModal}>
                Jetzt bearbeiten
              </SButton>
            </SActionButtonsWrapper>
          )}
        </CustomModalContent>
      )}
    />
  );
};

export default DataPrivacySupplierModal;
