import { Col } from "antd";

import PopoverInfo from "@components/form/popover-info/PopoverInfo";
import { LoadingHintText } from "@components/layout/loading-indicator/loading-hint-text";
import useOnboardingLabels from "@hooks/onboarding/labels/useOnboardingLabels";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { mapDBValue } from "@page-components/onboarding/Summary/helpers";
import { SectionWrapper } from "@page-components/onboarding/Summary/SectionWrapper";
import { ProfileKeyValue } from "@src/page-components/onboarding/Summary/ProfileKeyValue";
import { financialPositionState } from "@states/financial-position.state";
import { parseNumberToCurrencyString } from "smavesto.core/lib/utils/format/parseNumberToCurrencyString";

const FinancialPosition: React.FunctionComponent = () => {
  const [contentsLoadingIndicator, contents] = useAsyncSelector(
    financialPositionState("loggedInUser")
  );

  const labels = useOnboardingLabels();

  if (contentsLoadingIndicator === "loading") return <LoadingHintText />;

  return (
    <SectionWrapper isEmpty={!contents} title="Finanzielle Lage">
      {contents && (
        <Col span={15}>
          <ProfileKeyValue label="Anlageziel:" alignSelectionBottom>
            {mapDBValue("financialPosition", "strategy", contents.strategy)}
          </ProfileKeyValue>
          <ProfileKeyValue label="Anlagehorizont:" alignSelectionBottom>
            <PopoverInfo
              {...labels.investmentSettings.labelInfoTooltips.investmentPeriod}
            >
              {mapDBValue(
                "financialPosition",
                "investmentPeriod",
                contents.investmentPeriod
              )}
            </PopoverInfo>
          </ProfileKeyValue>
          <ProfileKeyValue label="Verlusttragfähigkeit:" alignSelectionBottom>
            <PopoverInfo
              {...labels.investmentSettings.labelInfoTooltips
                .lossBearingCapacity}
            >
              {contents.lossBearingCapacity === "SOME_LOSS"
                ? labels.financialSituation.fields.lossBearingCapacity.label
                : ""}
            </PopoverInfo>
          </ProfileKeyValue>

          <ProfileKeyValue
            label="Geld mtl. zur Verfügung in EUR:"
            alignSelectionBottom
          >
            <PopoverInfo
              {...labels.investmentSettings.labelInfoTooltips.monthlyIncome}
            >
              {parseNumberToCurrencyString(contents.monthlyIncome)}
            </PopoverInfo>
          </ProfileKeyValue>

          <ProfileKeyValue label="Liquides Vermögen:" alignSelectionBottom>
            <PopoverInfo
              {...labels.investmentSettings.labelInfoTooltips.liquidCapital}
            >
              {parseNumberToCurrencyString(contents.liquidCapital)}
            </PopoverInfo>
          </ProfileKeyValue>
        </Col>
      )}
    </SectionWrapper>
  );
};

export default FinancialPosition;
