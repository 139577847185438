import LockFilled from "@ant-design/icons/lib/icons/LockFilled";
import { Form, Modal, notification } from "antd";
import type { FormInstance } from "antd/es/form";

import { useContext } from "react";
import { ThemeContext } from "@pages/_app";
import {
  SActionButtonsWrapper,
  SButton
} from "@styled-components/form/SButtons";
import { SInput } from "@styled-components/form/SInputs";

interface LoginTanFormModalProps {
  isOpen: boolean;
  emailAddress: string;
  handleModalClose: () => void;
  form: FormInstance;
  loginForm: FormInstance;
  onFinish: (values: TanFormValues) => void;
  isLoading?: boolean;
}

export type TanFormValues = {
  tan: string;
};

const LoginTanFormModal: React.FunctionComponent<LoginTanFormModalProps> = ({
  isOpen,
  emailAddress,
  handleModalClose,
  form,
  onFinish,
  isLoading,
  loginForm
}) => {
  const { theme, appModeTheme } = useContext(ThemeContext);

  const channel = loginForm.getFieldValue("tanChannel");

  const handleFinish = (values: TanFormValues) => {
    if (values.tan && values.tan.trim().length > 0) {
      onFinish(values);
      handleModalClose();
    } else {
      notification.error({
        message: "Bitte geben sie eine gültige TAN ein."
      });
    }
  };

  return (
    <Modal
      width="100%"
      style={{
        maxWidth: 700,
        boxSizing: "border-box",
        color: appModeTheme?.secondLayerTextColor
      }}
      centered
      visible={isOpen}
      modalRender={() => (
        <div
          className="ant-modal-content"
          style={{
            background: appModeTheme?.modalBackgroundColor,
            padding: "64px 48px 0px 32px",
            borderRadius: 30,
            color: appModeTheme?.secondLayerTextColor
          }}
        >
          <div style={{ textAlign: "center", color: "inherit" }}>
            <h2 style={{ color: "inherit", marginBottom: 40 }}>
              Sicherheitsabfrage
            </h2>
            <LockFilled style={{ fontSize: 48, marginBottom: 20 }} />
            <p>
              Bitte bestätigen Sie den Login in Ihren Smavesto-Account, dafür
              benötigen wir die Eingabe eines Sicherheitscodes (
              {channel === "email" ? "E-Mail-TAN" : "SMS-TAN"})
              {channel === "email" && (
                <>
                  , die wir an folgende E-Mail-Adresse geschickt haben:{" "}
                  <b>{emailAddress}</b>
                </>
              )}
              .
            </p>
          </div>

          <Form<TanFormValues> form={form} onFinish={handleFinish}>
            <Form.Item name="tan">
              <SInput autoFocus title="TAN:" />
            </Form.Item>

            <SActionButtonsWrapper>
              <SButton className="reset" onClick={handleModalClose}>
                Abbrechen
              </SButton>

              <SButton className="reset" htmlType="submit" disabled={isLoading}>
                TAN bestätigen
              </SButton>
            </SActionButtonsWrapper>
          </Form>
        </div>
      )}
    />
  );
};

export default LoginTanFormModal;
