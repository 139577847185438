import { CloseCircleOutlined } from "@ant-design/icons";
import CustomModalContent from "@src/components/layout/modal/CustomModalContent";
import {
  SActionButtonsWrapper,
  SButton
} from "@src/styled-components/form/SButtons";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { Button, Modal } from "antd";
import { useRouter } from "next/router";
import type { NotificationModalSwitchProps } from "./NotificationModalSwitch";

const AdventCalendarModal: React.FunctionComponent<
  NotificationModalSwitchProps
> = ({ visible, userUri, onClose }) => {
  const router = useRouter();

  const saveDecline = async () => {
    await smavestoCore.services.keyValueStore.setValue(
      userUri,
      "events.adventCalendar",
      "decline"
    );

    onClose();
  };

  return (
    <Modal
      centered
      closable
      closeIcon={<CloseCircleOutlined />}
      onCancel={onClose}
      width="100%"
      style={{ maxWidth: 900 }}
      visible={visible}
      modalRender={() => (
        <CustomModalContent>
          <Button
            onClick={onClose}
            style={{
              color: "inherit",
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
              margin: "2rem",
              width: 40,
              height: 40
            }}
            type="text"
            icon={<CloseCircleOutlined />}
          />
          <h2 style={{ margin: 0, marginBottom: "1rem" }}>
            Adventskalender 2024
          </h2>

          <p>
            Die besinnliche Adventszeit steht vor der Tür, und wir von Smavesto,
            Ihrer digitalen Vermögensverwaltung, möchten diese besondere Zeit
            mit einem einzigartigen Adventskalender bereichern! Hinter jedem
            Türchen verbirgt sich nicht nur ein spannender Mythos oder
            faszinierender Fakt rund um das Thema Geldanlage und
            Vermögensaufbau, sondern auch tolle Gewinnspiele und exklusive
            Prämiencodes für unsere geschätzten Kunden.
            <br />
            <br />
            Besuchen Sie täglich unsere Website, um kein Türchen zu verpassen
            und Ihre Chance auf großartige Preise zu nutzen. Eine besinnliche
            Adventszeit wünscht Ihnen Ihr Smavesto-Team!
          </p>

          <SActionButtonsWrapper>
            <SButton className="reset" onClick={saveDecline}>
              Hinweis nicht mehr anzeigen
            </SButton>

            <SButton
              type="primary"
              onClick={() => {
                window.open("https://www.smavesto.de/adventskalender");
                onClose();
              }}
            >
              zum Adventskalender
            </SButton>
          </SActionButtonsWrapper>
        </CustomModalContent>
      )}
    />
  );
};

export default AdventCalendarModal;
