/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { LoadingOutlined, PaperClipOutlined } from "@ant-design/icons";
import moment from "moment";

import { useAcceptDeclineDoc } from "@page-components/dashboard/Inbox/attachment/useAcceptDeclineDoc";
import { useDownloadFile } from "@page-components/dashboard/Inbox/attachment/useDownloadFile";
import {
  SActionButtonsWrapper,
  SButton
} from "@styled-components/form/SButtons";
import { SCustomDivider } from "@styled-components/SCustomDivider";
import { SP } from "@styled-components/STags";
import type InboxDto from "smavesto.core/lib/types/dto/inbox/InboxDto";

interface ConfirmDocItemProps {
  file: InboxDto;
  index: number;
  onClose: () => void;
}

export const ConfirmDocItem: React.FunctionComponent<ConfirmDocItemProps> = ({
  file,
  index,
  onClose
}) => {
  const {
    isLoading: isDocLoading,
    onDownload,
    downloadFileRef
  } = useDownloadFile();

  const { isLoading, onAccept } = useAcceptDeclineDoc(onClose);

  const { title } = file;

  const firstAttachment =
    file.attachments.length > 0 ? file.attachments[0] : undefined;

  return (
    <>
      {index > 0 && <SCustomDivider style={{ margin: "20px auto 0" }} />}
      {firstAttachment && (
        <>
          <SP margin="20px 0 0">
            Das Dokument {firstAttachment.fileName} wurde von Ihrem
            Vermögensverwalter angepasst und es ist eine Zustimmung notwendig,
            wenn Sie Smavesto weiterhin nutzen wollen.
          </SP>

          <SP>
            Weitere Informationen zur Anpassungen finden Sie im Dokument (
            <a onClick={() => onDownload(firstAttachment.id)}>
              {isDocLoading ? <LoadingOutlined /> : <PaperClipOutlined />}{" "}
              {firstAttachment.fileName}
            </a>
            ) oder erhalten Sie auf Anfrage direkt von Smavesto.
          </SP>
        </>
      )}

      <SP color="gray" textAlign="right">
        Datum: {moment(file.date).format("DD.MM.YYYY")}
      </SP>

      {firstAttachment && (
        <SActionButtonsWrapper style={{ margin: 0 }}>
          <SButton className="reset" onClick={onClose}>
            Nicht jetzt
          </SButton>

          <SButton
            type="primary"
            loading={isLoading}
            onClick={() => onAccept(firstAttachment.id)}
          >
            Zustimmen
          </SButton>
        </SActionButtonsWrapper>
      )}

      <a ref={downloadFileRef} download={title} style={{ display: "none" }} />
    </>
  );
};
