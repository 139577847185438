import { VerticalAlignTopOutlined } from "@ant-design/icons";
import type { FormItemProps } from "antd";
import type { KeyboardEvent } from "react";
import { useContext, useState } from "react";

import { ThemeContext } from "@src/pages/_app";
import { SFormItem } from "@styled-components/form/SFormItems";
import { SPasswordInputWrapper } from "@styled-components/form/SInputs";
import Password from "antd/lib/input/Password";

interface Props extends FormItemProps {}

export const PasswordField: React.FunctionComponent<Props> = ({ ...props }) => {
  const [isCaps, setIsCaps] = useState(false);

  const { inputCssProperties } = useContext(ThemeContext);

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    e.getModifierState("CapsLock") && !isCaps && setIsCaps(true);
    !e.getModifierState("CapsLock") && isCaps && setIsCaps(false);
  };

  return (
    <SFormItem {...props}>
      <SPasswordInputWrapper>
        <Password
          bordered={false}
          style={inputCssProperties}
          onKeyUp={onKeyUp}
        />

        {isCaps && <VerticalAlignTopOutlined className="caps-icon" />}
      </SPasswordInputWrapper>
    </SFormItem>
  );
};
