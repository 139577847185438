import { notification } from "antd";

import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { openTanModalForm } from "@page-components/dashboard/Profile/helpers";
import { useApiErrorHandler } from "@src/hooks/error-handling/useApiErrorHandler";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { useRefreshFinancialPosition } from "@states/financial-position.state";
import { knowledgeState, useRefreshKnowledge } from "@states/knowledge.state";
import { useRefreshProfile } from "@states/profile.state";
import useThemedModal from "@theme/hook/useThemedModal";

import type ProfileDto from "smavesto.core/lib/types/dto/profile/ProfileDto";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import { SUCCESS_NOTIFICATION } from "@src/page-components/dashboard/Profile/staticNotifications";

export type FormValues = Partial<
  Omit<
    ProfileDto,
    | "email"
    | "birthDate"
    | "gender"
    | "title"
    | "firstName"
    | "lastName"
    | "birthCountry"
    | "birthCity"
    | "nationality"
    | "propertyRegime"
  >
>;

export const useModalProfileVerification = (onClose: () => void) => {
  const { contextHolder, openModal } = useThemedModal();

  const [handleApiError] = useApiErrorHandler();

  const [, knowledge] = useAsyncSelector(knowledgeState("loggedInUser"));

  const refreshFinancialPosition = useRefreshFinancialPosition("loggedInUser");

  const refreshProfile = useRefreshProfile("loggedInUser");

  const refreshKnowledge = useRefreshKnowledge("loggedInUser");

  const updateData = async (
    {
      userUri,
      tan,
      channel
    }: { userUri: string; tan: string; channel: string },
    destroyTanModal: () => void
  ) => {
    if (!knowledge) return;

    const setKnowledgeResponse = await smavestoCore.services.user.setKnowledge(
      userUri,
      knowledge,
      tan,
      channel
    );

    if (isApiErrorResponse(setKnowledgeResponse)) {
      handleApiError(setKnowledgeResponse, "modal", "error");
      destroyTanModal();
      return;
    }

    refreshKnowledge();
    refreshProfile();
    refreshFinancialPosition();

    notification.success(SUCCESS_NOTIFICATION);

    onClose();
  };

  const onConfirm = () => openTanModalForm(openModal, updateData);

  return {
    openModal,
    onConfirm,
    contextHolder
  };
};
