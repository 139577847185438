import { ConfirmDocItem } from "@src/components/layout/page-container/SecureLayout/modal-confirm-docs/ConfirmDocItem";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { ThemeContext } from "@src/pages/_app";
import { inboxToConfirmState } from "@src/state/inbox.state";
import { SH2 } from "@src/styled-components/STags";
import { Modal } from "antd";
import { useContext } from "react";
import type { NotificationModalSwitchProps } from "./NotificationModalSwitch";

const ConfirmDocumentsNotificationModal: React.FunctionComponent<
  NotificationModalSwitchProps
> = ({ visible, onClose }) => {
  const { appModeTheme } = useContext(ThemeContext);

  const [, inboxToConfirm] = useAsyncSelector(
    inboxToConfirmState("loggedInUser")
  );

  return (
    <Modal
      width={900}
      onCancel={onClose}
      centered
      visible={visible}
      modalRender={() => (
        <div
          className="ant-modal-content"
          style={{
            background: appModeTheme?.modalBackgroundColor,
            color: appModeTheme?.secondLayerTextColor,
            padding: 24
          }}
        >
          <SH2 margin="0px 0 20px">Zustimmungspflichtige Dokumente</SH2>

          {inboxToConfirm?.map((doc, index) => (
            <ConfirmDocItem
              onClose={onClose}
              index={index}
              key={doc.id}
              file={doc}
            />
          ))}
        </div>
      )}
    />
  );
};

export default ConfirmDocumentsNotificationModal;
