import { CloseCircleOutlined } from "@ant-design/icons";
import CustomModalContent from "@src/components/layout/modal/CustomModalContent";
import {
  SActionButtonsWrapper,
  SButton
} from "@src/styled-components/form/SButtons";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { Button, Modal } from "antd";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import type { NotificationModalSwitchProps } from "./NotificationModalSwitch";

const SurveyNotificationModal: React.FunctionComponent<
  NotificationModalSwitchProps
> = ({ visible, userUri, onClose }) => {
  const surveyUrl =
    "https://fmru.az1.qualtrics.com/jfe/form/SV_9H0Nnb6KFcq1NRA";

  const openSurveyUrl = async (userUri: string) => {
    await smavestoCore.services.keyValueStore.setSurveyState(
      userUri,
      "started"
    );

    const pseudonym =
      await smavestoCore.services.keyValueStore.getUserPseudonym(userUri);
    const investmentSettings =
      await smavestoCore.services.user.getInvestmentSettings(userUri);

    if (isApiErrorResponse(investmentSettings))
      throw new Error("Error while loading investment settings.");

    // This params will be added to the survey url
    const additionalQueryParams = [
      {
        key: "USER_ID",
        value: pseudonym
      },
      {
        key: "SUSTAINABILITY",
        value: investmentSettings.onlySustainableCompanies ? "true" : "false"
      }
    ];

    // Open the survey url in a new tab
    window.open(
      `${surveyUrl}?${additionalQueryParams
        .map(queryItem => `${queryItem.key}=${queryItem.value}`)
        .join("&")}`
    );

    onClose();
  };

  const onCloseAndDecline = (userUri: string) => {
    smavestoCore.services.keyValueStore
      .setSurveyState(userUri, "cancelled")
      .then(() => onClose());
  };

  return (
    <Modal
      centered
      closable
      closeIcon={<CloseCircleOutlined />}
      onCancel={onClose}
      width="100%"
      style={{ maxWidth: 900 }}
      visible={visible}
      modalRender={() => (
        <CustomModalContent>
          <Button
            onClick={onClose}
            style={{
              color: "inherit",
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
              margin: "2rem",
              width: 40,
              height: 40
            }}
            type="text"
            icon={<CloseCircleOutlined />}
          />
          <h2 style={{ margin: 0, marginBottom: "1rem" }}>Hinweis</h2>

          <p>
            Im Folgenden können Sie an einer pseudonymisierten Umfrage mit
            Gewinnspiel der Universität Bremen in Zusammenarbeit mit Smavesto
            teilnehmen. Die Teilnahme an der Umfrage dauert nur ca. 2 Minuten.
            Die zehn Umfrage-Teilnehmenden mit den besten Schätzungen erhalten
            jeweils 100€.
          </p>
          <p>
            Mit Teilnahme an der Umfrage verlassen Sie die Seite von Smavesto
            und kommen auf die Seite der Universität Bremen.
          </p>

          {userUri && (
            <SActionButtonsWrapper>
              <SButton
                className="reset"
                onClick={() => onCloseAndDecline(userUri)}
              >
                Nicht teilnehmen
              </SButton>

              <SButton type="primary" onClick={() => openSurveyUrl(userUri)}>
                Teilnehmen
              </SButton>
            </SActionButtonsWrapper>
          )}
        </CustomModalContent>
      )}
    />
  );
};

export default SurveyNotificationModal;
