import { useApiErrorHandler } from "@src/hooks/error-handling/useApiErrorHandler";
import useLoadingState from "@src/hooks/state-utils/useLoadingState";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { depotsState } from "@src/state/depot.state";
import { profileStateForUserUri } from "@src/state/profile.state";
import { useRefreshState } from "@src/state/request.state";
import { getDepotsWithPendingCancellation } from "@src/utils/account/cancelation/getDepotsWithPendingCancellation";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { Button, Modal } from "antd";
import getCancellationStatus from "smavesto.core/lib/business-logic/cancellation/getCancellationStatus";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import type { NotificationModalSwitchProps } from "./NotificationModalSwitch";

const CancellationNotificationModal: React.FunctionComponent<
  NotificationModalSwitchProps
> = ({ visible, userUri, onClose }) => {
  const refreshDepots = useRefreshState("depots", "loggedInUser");
  const refreshCancellationDate = useRefreshState(
    "cancellationDate",
    "loggedInUser"
  );

  const [handleApiError] = useApiErrorHandler();

  const [, depots] = useAsyncSelector(depotsState);
  const [, profile] = useAsyncSelector(profileStateForUserUri(userUri));

  const { isLoading, performMethod } = useLoadingState();

  const pendingCancellations = depots
    ? getDepotsWithPendingCancellation(depots)
    : [];

  if (!pendingCancellations.length) return null;

  const [firstPendingCancellationDepot] = pendingCancellations;

  if (!profile) return null;

  const cancellationStatus = getCancellationStatus(
    firstPendingCancellationDepot,
    profile
  );

  const approveCancellation = () =>
    performMethod(() =>
      smavestoCore.services.user
        .performCancellation(firstPendingCancellationDepot.userUri, "Approve")
        .then(() => {
          refreshDepots();
          refreshCancellationDate();
        })
        .then(result =>
          isApiErrorResponse(result)
            ? handleApiError(result, "modal", "error")
            : onClose()
        )
    );

  const rejectCancellation = () =>
    performMethod(() =>
      smavestoCore.services.user
        .performCancellation(firstPendingCancellationDepot.userUri, "Reject")
        .then(() => {
          refreshDepots();
          refreshCancellationDate();
        })
        .then(result =>
          isApiErrorResponse(result)
            ? handleApiError(result, "modal", "error")
            : onClose()
        )
    );

  if (!cancellationStatus) return null;

  return (
    <Modal
      okType="danger"
      width={600}
      centered
      title="Hinweis: Eine Kündigung wurde angestoßen"
      okText="Kündigung zustimmen"
      cancelText="Kündigung abbrechen"
      visible={visible}
      footer={[
        <Button disabled={isLoading} key="1" onClick={onClose}>
          Fenster schließen
        </Button>,
        <Button disabled={isLoading} key="2" onClick={rejectCancellation}>
          Kündigung abbrechen
        </Button>,
        <Button
          disabled={isLoading}
          key="3"
          type="primary"
          onClick={approveCancellation}
        >
          Kündigung zustimmen
        </Button>
      ]}
      closable
      onCancel={onClose}
    >
      {cancellationStatus.pendingCancellation?.description}
    </Modal>
  );
};

export default CancellationNotificationModal;
