import { Progress } from "antd";
import { useContext } from "react";
import type { RecoilLoadingStateDetails } from "@utils/recoil/bundledLoadingState";
import { ThemeContext } from "@pages/_app";

interface BundledLoadingIndicatorProps {
  loadingStateDetails: RecoilLoadingStateDetails;
}

const BundledLoadingIndicator: React.FunctionComponent<
  BundledLoadingIndicatorProps
> = ({ loadingStateDetails }) => {
  const { theme, appModeTheme } = useContext(ThemeContext);

  return (
    <div style={{ width: 10 }}>
      <Progress
        className="animation-spin"
        width={40}
        type="circle"
        showInfo
        format={() => ""}
        style={{
          color: theme.palette.primary,
          background:
            appModeTheme?.colorSchema === "light" ? "white" : "transparent",
          borderRadius: 300,
          padding: 10
        }}
        strokeColor={
          appModeTheme?.colorSchema === "light"
            ? theme.palette.primary
            : "white"
        }
        trailColor="transparent"
        percent={loadingStateDetails.loadingProgress}
      />

      {/*  {loadingStateDetails.states.map((o) => (
        <p>
          {o.key}-{o.value}
        </p>
      ))} */}

      {loadingStateDetails.state === "hasError" && (
        <>Beim Laden der Seite ist ein Fehler aufgetreten.</>
      )}
    </div>
  );
};

export default BundledLoadingIndicator;
