import type { FormProps } from "antd";
import { Col, Row } from "antd";
import type { FC } from "react";
import React, { useEffect, useMemo, useState } from "react";

import { InputField } from "@components/form/inputs/form-fields/InputField";
import { PasswordField } from "@components/form/inputs/form-fields/PasswordField";
import { SButton } from "@styled-components/form/SButtons";

import { LockOutlined } from "@ant-design/icons";
import { RadioGroup } from "@src/components/form/inputs/form-fields/RadioGroup";
import { RADIO_TAN } from "@src/page-components/onboarding-underage/CustodyInfo/constants";
import { SFormCollapse } from "@src/styled-components/SCollapse";
import { SForegroundCol } from "@src/styled-components/SForegroundCol";
import useIsMobile from "@src/theme/hook/useIsMobile";
import FormItem from "antd/lib/form/FormItem";
import { useRouter } from "next/router";
import { RULES } from "../registration/registration-submit/constants";
import LoginTanFormModal from "./LoginTanFormModal";
import { SLoginForm } from "./styled";
import type { LoginFormValues } from "./useLoginForm";
import { useLoginForm } from "./useLoginForm";

interface LoginFormProps {
  isUnderageMode?: boolean;
  isLoading?: boolean;
  require2FA?: boolean;
  onBackClick?: () => void;
  onLoginSuccess?: (userUri: string) => void;
  children?: React.ReactNode;
  dontHideLoginFormOnSubmit?: boolean;
  disableNotifications?: boolean;
  title?: string;
}

export const LoginForm: React.FunctionComponent<LoginFormProps> = ({
  onLoginSuccess,
  isLoading,
  isUnderageMode,
  require2FA,
  onBackClick,
  children,
  dontHideLoginFormOnSubmit,
  disableNotifications,
  title
}) => {
  const { query } = useRouter();

  const { email, ref, loggedOut } = query;

  const isMobile = useIsMobile();

  const [forgotPassword, setForgotPassword] = useState(false);

  useEffect(() => {
    if (query && query.section === "reset" && !forgotPassword)
      setForgotPassword(true);
  }, [query]);

  const [
    loginForm,
    loginWithTanForm,
    onFinishLoginForm,
    onFinishTanForm,
    onReset,
    errorMessage,
    is2FAModalOpen,
    close2FAModal,
    emailAddress,
    isRefreshLoading,
    contextHolder,
    displayLoginForm,
    tanHelpExpanded
  ] = useLoginForm(
    require2FA || false,
    forgotPassword,
    onLoginSuccess,
    disableNotifications
  );

  const [tanCollapseActiveKey, setTtanCollapseActiveKey] = useState<string>();

  useEffect(() => {
    if (tanHelpExpanded === true) {
      setTtanCollapseActiveKey("1");
    }
  }, [tanHelpExpanded]);

  const isLoadingAndDisabled = useMemo<boolean>(() => {
    if (isRefreshLoading === true || isLoading === true) return true;

    return false;
  }, [isLoading, isRefreshLoading]);

  const shouldHideLoginForm = useMemo<boolean>(() => {
    if (displayLoginForm === false && dontHideLoginFormOnSubmit !== true)
      return true;

    return false;
  }, [displayLoginForm, dontHideLoginFormOnSubmit]);

  const onForgotPasswordClick = () => {
    setForgotPassword(!forgotPassword);
    onReset();
  };

  const onUnderageCancelClick = () => {
    onBackClick && onBackClick();
    onReset();
  };

  if (shouldHideLoginForm) return contextHolder;

  const { Panel } = SFormCollapse;

  const forgotPWButton = (
    <SButton
      onClick={onForgotPasswordClick}
      type="link"
      className="link"
      style={{ margin: "auto" }}
    >
      {forgotPassword ? "Zurück zum Login" : "Passwort vergessen?"}
    </SButton>
  );

  return (
    <SForegroundCol>
      <SLoginForm<FC<FormProps<LoginFormValues>>>
        form={loginForm}
        layout="vertical"
        onFinish={onFinishLoginForm}
        style={isUnderageMode ? { margin: 0 } : {}}
      >
        <Row>
          <Col span={24} className="head-title">
            {forgotPassword ? "Passwort vergessen" : title || "Login"}
          </Col>

          {children && <Row>{children}</Row>}

          {loggedOut && (
            <Col span={24} className="logout-hint">
              Sie wurden automatisch abgemeldet, da Ihre Anmeldung abgelaufen
              ist. Bitte melden Sie sich erneut an.
            </Col>
          )}

          {ref && (
            <Col span={24} className="ref-hint">
              Bitte melden Sie sich an, um fortzufahren.
            </Col>
          )}
          {forgotPassword && (
            <Col span={24} className="head-subtitle">
              Sie haben Ihr Passwort vergessen? Dann können Sie hier einen Link
              zum Zurücksetzen des Passworts anfordern.
            </Col>
          )}
        </Row>

        {require2FA && (
          <>
            Zum Anmelden benötigen wir im nächsten Schritt die Eingabe eines
            Sicherheitscodes (SMS-TAN), den wir an Ihre im Profil hinterlegte
            Telefonnummer senden.
            <SFormCollapse
              onChange={key => setTtanCollapseActiveKey(key as string)}
              activeKey={tanCollapseActiveKey}
            >
              <Panel
                header="Ich habe keinen Zugriff auf meine Telefonnummer, um die SMS-TAN zu erhalten."
                key="1"
              >
                <FormItem initialValue="sms" name="tanChannel">
                  <RadioGroup
                    name="tanChannel"
                    items={RADIO_TAN}
                    defaultValue="sms"
                  />
                </FormItem>
              </Panel>
            </SFormCollapse>
          </>
        )}

        <InputField
          name="email"
          label="E-Mail *"
          rules={RULES.email}
          inputProps={{ type: "email" }}
          initialValue={email ?? email}
        />

        {forgotPassword === false && (
          <PasswordField
            name="password"
            label="Passwort *"
            rules={[
              { required: true, message: "Bitte geben Sie Ihr Passwort ein." }
            ]}
          />
        )}

        <Row style={{ marginTop: 40 }}>
          {isUnderageMode && (
            <Col xs={24} sm={10}>
              <SButton
                style={{ margin: "auto" }}
                htmlType="reset"
                className="reset"
                onClick={onUnderageCancelClick}
              >
                Zurück
              </SButton>
            </Col>
          )}

          {!require2FA && (
            <Col xs={24} sm={12}>
              {forgotPWButton}
            </Col>
          )}

          <Col xs={24} sm={require2FA ? 14 : 12}>
            <SButton
              icon={require2FA && <LockOutlined style={{ fontSize: 22 }} />}
              htmlType="submit"
              type="primary"
              style={{ margin: "auto", marginTop: isMobile ? 30 : 0 }}
              disabled={isLoadingAndDisabled}
            >
              {forgotPassword
                ? "Neues Passwort anfordern"
                : `Anmelden${require2FA ? " und TAN anfordern" : ""}`}
            </SButton>
          </Col>
        </Row>
      </SLoginForm>
      <LoginTanFormModal
        form={loginWithTanForm}
        loginForm={loginForm}
        onFinish={onFinishTanForm}
        emailAddress={emailAddress}
        handleModalClose={close2FAModal}
        isOpen={is2FAModalOpen}
        isLoading={isLoadingAndDisabled}
      />
      {contextHolder}
    </SForegroundCol>
  );
};
