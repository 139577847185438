import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { useEffect, useState } from "react";
import type { NotificationType } from "smavesto.core/lib/business-logic/notifications/types/NotificationType";
import type { NotificationModalSwitchProps } from "./NotificationModalSwitch";
import NotificationModalSwitch from "./NotificationModalSwitch";

const NotificationContextHolder: React.FunctionComponent<
  NotificationModalSwitchProps & {
    notificationArray: NotificationType[];
  }
> = ({ onClose, userUri, notificationArray }) => {
  const [currentNotificationIndex, setCurrentNotificationIndex] =
    useState<number>(0);

  smavestoCore.debugLog(
    "info",
    "Found notficiations for user:",
    notificationArray
  );

  const [finished, setFinished] = useState<boolean>(false);

  const continueToNextNotification = () => {
    setCurrentNotificationIndex(index => index + 1);
  };

  useEffect(() => {
    /* There are no notifications or the last notification has been reached */
    if (
      !finished &&
      (notificationArray.length === 0 ||
        currentNotificationIndex > notificationArray.length - 1)
    ) {
      onClose();
      setFinished(true);
    }
  }, [notificationArray, currentNotificationIndex]);

  if (finished) return null;

  return (
    <>
      {notificationArray.map(
        (notification: NotificationType, index: number) => (
          <NotificationModalSwitch
            userUri={userUri}
            visible={currentNotificationIndex === index}
            onClose={continueToNextNotification}
            type={notification}
          />
        )
      )}
    </>
  );
};

export default NotificationContextHolder;
