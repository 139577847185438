import * as Sentry from "@sentry/nextjs";
import { Form, notification } from "antd";
import type { FormInstance } from "antd/es/form";
import { useRouter } from "next/router";
import type { ReactElement } from "react";
import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import {
  expiresState,
  loggedInUserUriState,
  refreshLoadingState
} from "@states/auth.state";
import type { ApiErrorResponse } from "smavesto.core/lib/types/api/generic/ApiErrorResponse";

import { useApiErrorHandler } from "@hooks/error-handling/useApiErrorHandler";
import useLoginNotifications from "@src/hooks/notifications/useOnLoginNotifications";
import wait from "@src/utils/async/wait";
import { getCookie } from "@src/utils/cookies";
import hideLettersOfString from "@src/utils/format/hideLettersOfString";
import getRedirectUrlAfterLogin from "@src/utils/redirect/getRedirectUrlAfterLogin";
import type AuthDto from "smavesto.core/lib/types/dto/auth/AuthDto";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

export type LoginFormValues = {
  email: string;
  password: string;
  tanChannel: string;
};

type TanFormValues = {
  tan: string;
};

export const useLoginForm = (
  require2FA: boolean,
  forgotPassword: boolean,
  onLoginSuccess?: (userUri: string) => void,
  disableNotifications?: boolean
): [
  FormInstance<LoginFormValues>,
  FormInstance<TanFormValues>,
  (values: LoginFormValues) => void,
  (values: TanFormValues) => void,
  () => void,
  string,
  boolean,
  () => void,
  string,
  boolean,
  ReactElement | null,
  boolean,
  boolean
] => {
  const { push, query } = useRouter();

  const { notificationHandler, contextHolder } = useLoginNotifications();

  const [handleApiError] = useApiErrorHandler();

  const [loginForm] = Form.useForm<LoginFormValues>();

  const [loginWithTanForm] = Form.useForm<TanFormValues>();

  const [isRefreshLoading, setIsRefreshLoading] =
    useRecoilState(refreshLoadingState);

  const [is2FAModalOpen, set2FAModalOpen] = useState(false);

  const setRealUserUri = useSetRecoilState(loggedInUserUriState);

  const setExpires = useSetRecoilState(expiresState);

  const [storedProfileFormValueFor2FA, setStoredProfileFormValueFor2FA] =
    useState<LoginFormValues>();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [displayLoginForm, setDisplayLoginForm] = useState<boolean>(true);

  const emailAddress = storedProfileFormValueFor2FA?.email || "";

  const close2FAModal = () => set2FAModalOpen(false);

  const [tanHelpExpanded, setTanHelpExpanded] = useState<boolean>(false);

  /* Form Defaults */
  useEffect(() => {
    if (require2FA) loginForm.setFieldsValue({ tanChannel: "sms" });
  }, []);

  const continueLoginProcess = async (
    userUri: string,
    onLoginSuccess?: (userUri: string) => void
  ) => {
    if (onLoginSuccess) onLoginSuccess(userUri);
    else if (query.ref) push(query.ref.toString());
    else {
      const depots = await smavestoCore.services.user.getDepots(userUri);

      if (isApiErrorResponse(depots)) throw new Error("Could not load depots.");

      const allOnboardingsAndOnboardingData = await Promise.all(
        depots.map(async depot => ({
          onboardingData:
            await smavestoCore.services.onboarding.getOnboardingData(
              depot.userUri
            ),
          depot
        }))
      );

      const activeOnboarding = allOnboardingsAndOnboardingData.find(
        onboarding =>
          !successOrUndefined(onboarding.onboardingData)?.onboardingCompleted
      );

      if (isApiErrorResponse(activeOnboarding?.onboardingData))
        throw new Error("Could not load active onboarding.");

      const activeOnboardingDepot = depots.find(
        depot => depot.userUri === activeOnboarding?.depot.userUri
      );

      if (isApiErrorResponse(activeOnboardingDepot))
        throw new Error("Could not load depots.");

      const continuePath = getRedirectUrlAfterLogin(
        depots || [],
        activeOnboardingDepot,
        activeOnboarding?.onboardingData
      );
      push(continuePath || "/dashboard/depot");
    }
  };

  const setErrorsForResponse = (errorResponse: ApiErrorResponse) => {
    if (errorResponse.key === "INVALID_CREDENTIALS") {
      const errorMessage = `Anmeldedaten falsch. Sie haben noch ${errorResponse.data?.remainingAttempts} von ${errorResponse.data.maxAttempts} Versuchen.`;

      loginForm.setFields([
        {
          name: "password",
          errors: [errorMessage]
        }
      ]);

      setErrorMessage(errorMessage);
    } else if (errorResponse.key === "MAX_ATTEMPTS_REACHED") {
      const errorMessage =
        "Sie haben sich zu oft mit den falschen Login-Daten angemeldet. Bitte überprüfen Sie Ihr E-Mail Postfach, um das Passwort zurückzusetzen.";
      loginForm.setFields([
        {
          name: "password",
          errors: [errorMessage]
        }
      ]);

      setErrorMessage(errorMessage);
    } else if (errorResponse.key === "validation.tan.invalid") {
      const errorMessage = "Der eingegebene TAN-Code war nicht korrekt.";
      loginForm.setFields([
        {
          name: "password",
          errors: [errorMessage]
        }
      ]);

      setErrorMessage(errorMessage);
      loginWithTanForm.resetFields();
      set2FAModalOpen(false);
    } else {
      handleApiError(errorResponse, "modal", "error");
    }
    setIsRefreshLoading(false);
  };

  const handleAuthentificationResponse = async (
    response: AuthDto | ApiErrorResponse
  ) => {
    if (isApiErrorResponse(response)) {
      setErrorsForResponse(response);
      return;
    }

    if (response.userIdent) {
      setErrorMessage("");
      setRealUserUri(response.userIdent);
      setExpires(response.expires);
      Sentry.setUser({ id: hideLettersOfString(response.userIdent, 2) });

      const onboardingData =
        await smavestoCore.services.onboarding.getOnboardingData(
          response.userIdent
        );

      if (isApiErrorResponse(onboardingData)) {
        handleApiError(onboardingData, "modal", "error");
        return;
      }

      setDisplayLoginForm(false);
      setIsRefreshLoading(true);

      /* wait 200ms each for the XSRF-TOKEN to be set */
      await Promise.all(
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(waitSequence =>
          !getCookie("XSRF-TOKEN", window.document.cookie)
            ? wait(waitSequence * 100)
            : Promise.resolve()
        )
      );

      if (!getCookie("XSRF-TOKEN", window.document.cookie))
        throw new Error("XSRF-TOKEN is missing.");

      if (disableNotifications) {
        continueLoginProcess(response.userIdent, onLoginSuccess);
        setIsRefreshLoading(false);
      } else {
        notificationHandler(() => {
          continueLoginProcess(response.userIdent, onLoginSuccess);
          setIsRefreshLoading(false);
        }, response.userIdent);
      }
    } else {
      // Reset TAN because its valid for one use only
      loginWithTanForm.resetFields();

      setIsRefreshLoading(false);
    }
  };

  const onReset = () => {
    loginForm.resetFields();
    loginWithTanForm.resetFields();
    setErrorMessage("");
  };

  const onFinishTanForm = (values: TanFormValues) => {
    if (!storedProfileFormValueFor2FA) return;

    setIsRefreshLoading(true);

    const { tan } = values;

    const { email, password } = storedProfileFormValueFor2FA;

    const channel = loginForm.getFieldValue("tanChannel");

    smavestoCore.services.auth
      .secondFactorAuthentication(email, password, channel, tan)
      .then(handleAuthentificationResponse);
  };

  const onFinishLoginForm = (values: LoginFormValues) => {
    const { email, password } = values;

    setIsRefreshLoading(true);

    if (forgotPassword) {
      smavestoCore.services.auth.forgotPassword(email).then(sentMail => {
        setIsRefreshLoading(false);

        loginForm.resetFields();

        if (sentMail) {
          notification.success({
            message:
              "Ihr Passwort wurde erfolgreich zurückgesetzt. Bitte überprüfen Sie Ihr E-Mail-Postfach, um ein neues Passwort zu vergeben.",
            placement: "top",
            duration: 10
          });
        } else {
          setErrorMessage(
            "Die Anfrage konnte nicht erfolgreich an die E-Mail-Adresse verschickt werden. Bitte überprüfen Sie Ihre E-Mail-Adresse."
          );
        }
      });
    } else if (require2FA) {
      const channel = loginForm.getFieldValue("tanChannel");

      smavestoCore.services.twilio
        .generateTan2FAAuth(email, password, channel)
        .then(response => {
          setIsRefreshLoading(false);

          if (!isApiErrorResponse(response)) {
            setStoredProfileFormValueFor2FA({
              email,
              password,
              tanChannel: channel
            });
            set2FAModalOpen(true);
          } else {
            if (response.key === "twilio.generate-tan.failure") {
              loginForm.setFieldsValue({
                tanChannel: "email"
              });
              setTanHelpExpanded(true);
            }

            setErrorsForResponse(response);
          }
        });
    } else {
      smavestoCore.services.auth
        .authentication(email, password)
        .then(handleAuthentificationResponse);
    }
  };

  return [
    loginForm,
    loginWithTanForm,
    onFinishLoginForm,
    onFinishTanForm,
    onReset,
    errorMessage,
    is2FAModalOpen,
    close2FAModal,
    emailAddress,
    isRefreshLoading,
    contextHolder,
    displayLoginForm,
    tanHelpExpanded
  ];
};
