import { LockOutlined } from "@ant-design/icons";
import FinancialPosition from "@src/components/layout/page-container/SecureLayout/modal-profile-verification/FinancialPosition";
import { Knowledge } from "@src/components/layout/page-container/SecureLayout/modal-profile-verification/Knowledge";
import { useModalProfileVerification } from "@src/components/layout/page-container/SecureLayout/modal-profile-verification/useModalProfileVerification";
import PersonalDataSection from "@src/page-components/dashboard/Profile/personal-data/PersonalData";

import BasicHint from "@src/components/layout/hints/BasicHint";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { ThemeContext } from "@src/pages/_app";
import { knowledgeState } from "@src/state/knowledge.state";
import {
  SActionButtonsWrapper,
  SButton
} from "@src/styled-components/form/SButtons";
import { Modal } from "antd";
import { useRouter } from "next/router";
import { useContext } from "react";
import type { NotificationModalSwitchProps } from "./NotificationModalSwitch";

const ProfileVerificationModal: React.FunctionComponent<
  NotificationModalSwitchProps
> = ({ visible, userUri, onClose }) => {
  const { push } = useRouter();

  const { theme, appModeTheme } = useContext(ThemeContext);

  const { onConfirm, contextHolder } = useModalProfileVerification(onClose);

  const [, knowledge] = useAsyncSelector(knowledgeState("loggedInUser"));

  const isDataMissing = knowledge?.experiences.crypto === undefined;

  return (
    <Modal
      width="80%"
      centered
      visible={visible}
      modalRender={() => (
        <div
          className="ant-modal-content"
          style={{
            background:
              appModeTheme?.modalBackgroundColor ||
              theme.palette.secondLayerBackgroundColor,
            color: appModeTheme?.secondLayerTextColor || "white",
            padding: 24
          }}
        >
          <h2>Überprüfung Ihrer Angaben</h2>
          <p>
            Wir sind gesetzlich dazu verpflichtet in regelmäßigen Abständen Ihre
            Daten zu überprüfen. Bitte überprüfen Sie Ihre Angaben in Ihrem
            Profil und korrigieren Sie ggf. Ihre Daten.
          </p>

          <PersonalDataSection
            hideEditButton
            underageMode={false}
            userUri={userUri}
          />

          <FinancialPosition />

          <Knowledge />

          {isDataMissing && (
            <BasicHint title="Ihre Angaben sind unvollständig">
              Bitte aktualisieren Sie Ihre Kenntnisse um die folgenden Angaben:
              <br />
              <br />
              <ul>
                <li>Erfahrungen in Kryptowährungen (Zertifikate)</li>
                <li>Kenntnisse von Kryptowährungen (Zertifikate)</li>
              </ul>
            </BasicHint>
          )}

          <SActionButtonsWrapper>
            <SButton className="reset" onClick={onClose}>
              Nicht jetzt
            </SButton>
            <SButton type="primary" onClick={() => push("/dashboard/profile")}>
              Angaben ändern
            </SButton>

            <SButton
              disabled={isDataMissing}
              icon={<LockOutlined style={{ fontSize: 22 }} />}
              type="primary"
              onClick={onConfirm}
            >
              Angaben bestätigen
            </SButton>
          </SActionButtonsWrapper>
          {contextHolder}
        </div>
      )}
    />
  );
};

export default ProfileVerificationModal;
