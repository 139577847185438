import { Form } from "antd";
import styled from "styled-components";

import theme from "@styles/theme";

export const SLoginForm = styled(Form)`
  color: inherit;
  padding: 0px 70px 70px 70px;

  margin: 0 auto;

  max-width: 700px;

  && .head-title {
    font-size: 25px;
    font-weight: 600;
    text-align: left;
  }

  && .logout-hint {
    background: ${theme.warning};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    border-radius: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  && .ref-hint {
    background: ${theme.primaryHover};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    color: inherit;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    border-radius: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  && .error-message {
    color: red;
    text-align: center;
  }

  && .ant-form-item {
    color: inherit;
    margin-bottom: 5px;
  }

  && .ant-divider-horizontal {
    margin: 5px 0;
  }

  && .wrap-addiction-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  && .addiction-info-text {
    width: 70%;
  }

  && .addiction-info {
    background: ${theme.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  && .required-field-info {
    color: rgba(255, 255, 255, 0.8);
    margin-top: 20px;
  }

  @media (max-width: 960px) {
    padding: 50px 25px 50px 25px;

    && .wrap-addiction-info {
      align-items: baseline;
      flex-direction: column;
    }

    && .head-title {
      font-size: 18px;
    }

    && .addiction-info-text {
      width: 100%;
    }

    && .addiction-info {
      margin-bottom: 10px;
    }
  }
`;

export const SWorkflowStepContent = styled.div`
  padding: 70px 70px 200px 70px;

  margin: 0;
  color: inherit;
  max-width: 800px;

  && .head-title {
    font-size: 25px;
    font-weight: 600;
    text-align: left;
  }

  @media (max-width: 960px) {
    padding: 50px 25px 50px 25px;

    && .wrap-addiction-info {
      align-items: baseline;
      flex-direction: column;
    }

    && .head-title {
      font-size: 18px;
    }

    && .addiction-info-text {
      width: 100%;
    }

    && .addiction-info {
      margin-bottom: 10px;
    }
  }
`;
