import type { RecoilLoadingStateDetails } from "@utils/recoil/bundledLoadingState";
import BundledLoadingIndicator from "./BundledLoadingIndicator";

interface FullPageBundledLoadingIndicatorProps {
  loadingStateDetails: RecoilLoadingStateDetails;
}

const FullPageBundledLoadingIndicator: React.FunctionComponent<
  FullPageBundledLoadingIndicatorProps
> = ({ loadingStateDetails }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
      }}
    >
      <BundledLoadingIndicator loadingStateDetails={loadingStateDetails} />
    </div>
  );
};

export default FullPageBundledLoadingIndicator;
