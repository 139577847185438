import { Collapse } from "antd";
import styled from "styled-components";

import theme from "@styles/theme";

export const SCollapseA = styled(Collapse)`
  color: inherit;
  background: transparent;
  border: none;
  border-top: 1px solid #1b4040;
  font-size: 16px;

  .ant-collapse-item {
    border-bottom: 1px solid #1b4040;
  }

  && .ant-collapse-header {
    padding: 18px 12px;
    color: inherit;
    font-size: 20px;

    .anticon-plus {
      font-size: 24px;
      right: 6px;
      color: ${theme.primary};
    }
  }

  .ant-collapse-content {
    border: none;
    background: transparent;
    color: inherit;
  }
`;

export const SCollapseB = styled(Collapse)`
  color: inherit;

  .ant-collapse-item {
    border-bottom: 1px solid ${theme.primary};
    .anticon-plus {
      color: ${theme.primary};
      font-size: 20px;
    }
    .ant-collapse-header {
      color: inherit;
      padding: 10px 35px 10px 0px;
      font-size: 16px;
    }
    .ant-collapse-header:hover {
      color: ${theme.primary};
    }
    .ant-collapse-content {
      color: inherit;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 12px 0;
    }
    .ant-collapse-content-box {
      font-size: 14px;
    }
  }
`;

export const SCollapseC = styled(Collapse)`
  color: inherit;

  .ant-collapse-item {
    border-bottom: 1px solid ${theme.primary};
    color: inherit;

    .ant-collapse-arrow {
      color: ${theme.primary};
      font-size: 32px !important;
    }

    .ant-collapse-header {
      color: inherit;
      padding: 10px 35px 10px 0px;
      font-size: 16px;
    }
    .ant-collapse-header:hover {
      color: ${theme.primary};
    }
    .ant-collapse-content {
      color: inherit;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 12px 0;
    }
    .ant-collapse-content-box {
      font-size: 14px;
    }
  }
`;

export const SFormCollapse = styled(Collapse)`
  background: transparent;
  border: none;
  color: inherit;

  font-size: 12px;

  .ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse-header-text {
    font-size: 14px;
    text-decoration: underline;
  }

  .ant-collapse-item > .ant-collapse-header {
    padding: 0;
  }

  .ant-collapse-header .anticon {
    font-size: 14px !important;
  }

  .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse-content-box span {
    font-size: 14px;
  }

  && .ant-collapse-header {
    margin-top: 1rem;
    margin-bottom: 0rem;
    color: inherit;
    font-size: 14px;
    .anticon {
      color: ${theme.primary};
    }
  }

  .ant-collapse-content {
    border: none;
    background: transparent;
    color: inherit;
  }
`;
