import FullPageBundledLoadingIndicator from "@src/components/layout/loading-indicator/bundles-loading/FullPageBundledLoadingIndicator";
import { depotsState } from "@src/state/depot.state";
import { inboxToConfirmState } from "@src/state/inbox.state";
import { knowledgeState } from "@src/state/knowledge.state";
import { simulateDateState } from "@src/state/simulate.state";
import { detailedBundledLoadingState } from "@src/utils/recoil/bundledLoadingState";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import moment from "moment";
import { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import getNotificationsForUserUri from "smavesto.core/lib/business-logic/notifications/getNotificationsForUserUri";
import type { NotificationType } from "smavesto.core/lib/business-logic/notifications/types/NotificationType";
import { useAsyncSelector } from "../useAsyncSelector";
import NotificationContextHolder from "./components/NotificationContextHolder";

const useLoginNotifications = () => {
  const simulateDate = useRecoilValue(simulateDateState);

  /* this should fire after any state is fully loaded */
  const onInitialize = () => {
    if (shouldUpdateNotifications) {
      /* This should never happen */
      if (
        !userUri ||
        !depots ||
        depotsLoadingState === "loading" ||
        knowledgeLoadingState === "loading" ||
        inboxToConfirmLoadingState === "loading"
      )
        return;

      console.log(
        "simulateDate",
        simulateDate,
        moment(simulateDate).isBetween(
          "2024-12-01",
          "2024-12-24",
          undefined,
          "[]"
        )
      );
      /* grab all notifications for this user */
      getNotificationsForUserUri(
        smavestoCore,
        userUri,
        depots,
        knowledge,
        inboxToConfirm,
        simulateDate
      ).then(arr => {
        smavestoCore.debugLog("info", "Found notifications for user:", arr);
        setNotificationArray(arr);
      });
    }
  };

  const [onContinueMethod, setOnContinueMethod] = useState<() => void>();

  const [userUri, setUserUri] = useState<string>();

  const [knowledgeLoadingState, knowledge] = useAsyncSelector(
    knowledgeState("loggedInUser"),
    onInitialize
  );
  const [depotsLoadingState, depots] = useAsyncSelector(
    depotsState,
    onInitialize
  );
  const [inboxToConfirmLoadingState, inboxToConfirm] = useAsyncSelector(
    inboxToConfirmState("loggedInUser"),
    onInitialize
  );

  const [notificationArray, setNotificationArray] =
    useState<NotificationType[]>();

  const shouldUpdateNotifications = useMemo<boolean>(() => {
    return (
      !notificationArray &&
      !!depots &&
      inboxToConfirmLoadingState === "hasValue" &&
      knowledgeLoadingState === "hasValue" &&
      !!userUri &&
      !!onContinueMethod
    );
  }, [
    notificationArray,
    depots,
    inboxToConfirm,
    knowledge,
    userUri,
    onContinueMethod,
    inboxToConfirmLoadingState,
    knowledgeLoadingState
  ]);

  const bundledState = detailedBundledLoadingState([
    knowledgeLoadingState,
    depotsLoadingState,
    inboxToConfirmLoadingState
  ]);

  const notificationHandler = (onContinue: () => void, userUri: string) => {
    setUserUri(userUri);
    setOnContinueMethod(() => onContinue);
  };

  let contextHolder = null;

  if (
    onContinueMethod &&
    (!notificationArray || notificationArray.length === 0)
  )
    contextHolder = (
      <FullPageBundledLoadingIndicator loadingStateDetails={bundledState} />
    );

  if (notificationArray && userUri && onContinueMethod)
    contextHolder = (
      <NotificationContextHolder
        visible
        userUri={userUri}
        onClose={onContinueMethod}
        notificationArray={notificationArray}
      />
    );

  return { contextHolder, notificationHandler };
};

export default useLoginNotifications;
