import {
  getNextStepToOnboarding,
  getNextStepToUnderageOnboarding
} from "@src/components/layout/page-container/SecureLayout/onboardingSteps";
import type { DepotDto, OnboardingDto } from "smavesto.core";
import isJuniorDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";

const getRedirectUrlAfterLogin = (
  depots: DepotDto[],
  ativeOnboardingDepot?: DepotDto,
  ativeOnboarding?: OnboardingDto
) => {
  if (!ativeOnboarding) return undefined;

  const numberOfDepots = depots.length;

  if (numberOfDepots === 0) return "/dashboard/user-has-no-depot";

  const underageDepot = ativeOnboardingDepot
    ? isJuniorDepot(ativeOnboardingDepot)
    : false;

  const userIsSecondGuardian =
    ativeOnboardingDepot?.customerToCustomerRelationshipType === "GUARDIAN_2";

  // Check if the user has more than one depot
  const hasMultipleDepots = numberOfDepots > 1;

  /* More than one depot, the user needs to be able to open the dashboard */
  if (hasMultipleDepots) return undefined;

  /* open onboarding is a junior depot */
  if (underageDepot) {
    if (
      !userIsSecondGuardian &&
      ativeOnboarding.completedChapters.indexOf(
        "DOKUMENTE_GESETZLICHER_VERTRETER_1"
      ) > -1
    ) {
      return "/dashboard/depot";
    }

    return getNextStepToUnderageOnboarding(
      ativeOnboarding.completedChapters,
      userIsSecondGuardian,
      true
    );
  }

  /* open onboarding is a regular depot */
  return getNextStepToOnboarding(ativeOnboarding.completedChapters);
};

export default getRedirectUrlAfterLogin;
