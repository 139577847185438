import { useState } from "react";

import { useApiErrorHandler } from "@hooks/error-handling/useApiErrorHandler";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import { useRefreshState } from "@src/state/request.state";
import { userUriState } from "@states/auth.state";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

export const useAcceptDeclineDoc = (afterAction?: () => void) => {
  const [isLoading, setIsLoading] = useState(false);
  const [, userUri] = useAsyncSelector(userUriState("loggedInUser"));

  const [handleApiError] = useApiErrorHandler();

  const refreshInboxToConfirm = useRefreshState(
    "inboxToConfirm",
    "loggedInUser"
  );

  const refreshInbox = useRefreshState("inbox", "loggedInUser");

  const onAccept = async (id: string) => {
    if (!userUri) return;

    setIsLoading(true);

    const acceptInboxResponse = await smavestoCore.services.inbox.accept(
      userUri,
      id
    );

    if (isApiErrorResponse(acceptInboxResponse)) {
      handleApiError(acceptInboxResponse, "modal", "error");
      setIsLoading(false);
      return;
    }

    refreshInboxToConfirm();
    refreshInbox();
    setIsLoading(false);

    if (afterAction) afterAction();
  };

  const onDecline = async (id: string) => {
    if (!userUri) return;

    setIsLoading(true);

    const declineInboxResponse = await smavestoCore.services.inbox.decline(
      userUri,
      id
    );

    if (isApiErrorResponse(declineInboxResponse)) {
      handleApiError(declineInboxResponse, "modal", "error");
      setIsLoading(false);
      return;
    }

    refreshInboxToConfirm();

    setIsLoading(false);

    if (afterAction) afterAction();
  };

  return { isLoading, onAccept, onDecline };
};
