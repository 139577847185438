import type { RuleObject } from "antd/lib/form";

export const RULES = {
  email: [
    {
      required: true,
      type: "email" as const,
      message: "Bitte geben Sie eine gültige E-Mail-Adresse ein."
    }
  ],

  password: [
    {
      required: true,
      message: "Passwort ist ein Pflichtfeld."
    },
    {
      type: "string" as const,
      min: 8,
      message: "Passwort muss mindestens 8 Zeichen lang sein."
    },
    {
      required: true,
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+|/:?])/,
      message:
        "Passwort muss min. 4 verschiedene Zeichenklassen enthalten ( a-z | A-Z | 0-9 | !@#$%^&*+|/:? )"
    }
  ],

  passwordRepeat: [
    { required: true, message: "Bitte bestätigen Sie Ihr Passwort!" }
  ],

  zustimmenDatenschutzrichtlinien: [
    {
      validator: (_: RuleObject, value: boolean) =>
        value
          ? Promise.resolve()
          : Promise.reject(
              new Error("Bitte akzeptieren Sie die Datenschutzrichtlinien")
            )
    }
  ],

  zustimmenNutzungsbedingungen: [
    {
      validator: (_: RuleObject, value: boolean) =>
        value
          ? Promise.resolve()
          : Promise.reject(
              new Error("Bitte akzeptieren Sie die Nutzungsbedingungen")
            )
    }
  ]
};
